module.exports = [{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://moneyman-com-es.frm-qa.idfaws.com/wp/graphql","schema":{"perPage":8,"requestConcurrency":4,"previewRequestConcurrency":4,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000},"catchLinks":false,"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Moneyman COM ES landing","short_name":"Moneyman COM ES","start_url":"/","background_color":"#fff","display":"minimal-ui","icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d5728d4a867e68f17e4edb320fe5858d"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
